<script lang="ts" setup>
import type { WishlistNotificationsProps } from '@integration-layer/components/Wishlist/WishlistNotifications.props'

withDefaults(defineProps<WishlistNotificationsProps>(), {
  duration: 5,
})

const {
  wishlistFeedbackNotifications,
  deleteNotification,
  getSnackbarMessage,
  clearWishlistNotifications,
} = useWishlistFeedback()

const { restoreItem, restoreWishlist } = await useWishlist()
clearWishlistNotifications()
</script>

<template>
  <UtilsSnackbarGroupWrapper>
    <UtilsSnackbar
      v-for="notification in wishlistFeedbackNotifications"
      :id="`wishlist-${notification[0]}`"
      :key="notification[0]"
      role="dialog"
      :message="
        getSnackbarMessage({
          action: notification[1].action,
          productId: notification[0],
        })?.message
      "
      :action-message="
        getSnackbarMessage({
          action: notification[1].action,
          productId: notification[0],
        })?.actionMessage
      "
      :duration
      is-feedback
      @update:action="
        getSnackbarMessage({
          action: notification[1].action,
          productId: notification[0],
          actionsCb: {
            remove: () => {
              restoreItem(notification[1].item)
            },
            removeAll: restoreWishlist,
          },
        })?.action
      "
      @update:close="deleteNotification(notification[0])"
    />
  </UtilsSnackbarGroupWrapper>
</template>
